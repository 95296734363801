import React, { Component } from "react";
// import Gallery from "react-photo-gallery";
import StackGrid from "react-stack-grid";
import { GatsbyImage } from "gatsby-plugin-image";

export class GalleryElm extends Component {
  state = {
    windowWidth: null,
    date: new Date().toLocaleString(),
  };

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  updateDimensions = (_) => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    return (
      <div className="container event_gallery">
        {this.props.fotki.some(
          (item) => item.node.eventDate > this.state.date
        ) ? (
          <h2 className="events_heading">Future</h2>
        ) : (
          ""
        )}
        <StackGrid
          columnWidth={
            this.state.windowWidth > 1340
              ? "33.33%"
              : this.state.windowWidth > 669
              ? "50%"
              : "100%"
          }
          horizontal={false}
          gutterWidth={16}
          gutterHeight={19}
        >
          {this.props.fotki.map((item, i) => {
            return item.node.flyerImage &&
              item.node.eventDate > this.state.date ? (
              <div>
                <div
                  className="gallery_thumb"
                  onClick={(_) => {
                    this.props.showModal(i);
                  }}
                >
                  <GatsbyImage
                    image={item.node.flyerImage.gatsbyImageData}
                    key={`slider-img-${i}`}
                    alt={item.node.flyerImage.description}
                  />
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </StackGrid>
        <h2 className="events_heading">Past</h2>
        <StackGrid
          columnWidth={
            this.state.windowWidth > 1340
              ? "33.33%"
              : this.state.windowWidth > 669
              ? "50%"
              : "100%"
          }
          horizontal={false}
          gutterWidth={16}
          gutterHeight={19}
        >
          {this.props.fotki.map((item, i) => {
            return item.node.flyerImage &&
              item.node.eventDate < this.state.date ? (
              <div>
                <div
                  className="gallery_thumb"
                  onClick={(_) => {
                    this.props.showModal(i);
                  }}
                >
                  <GatsbyImage
                    image={item.node.flyerImage.gatsbyImageData}
                    key={`slider-img-${i}`}
                    alt={item.node.flyerImage.description}
                  />
                </div>
              </div>
            ) : (
              ""
            );
          })}
        </StackGrid>
      </div>
    );
  }
}

export default GalleryElm;
