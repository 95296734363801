import React from "react";
import { graphql, StaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

// import SEO from "../components/seo";
import GalleryElm from "../components/GalleryElm";
import Carousel, { Modal, ModalGateway } from "react-images";
// import ReactTextCollapse from "react-text-collapse";

export class EventsGallery extends React.Component {
  state = {
    modalIsOpen: false,
    modalIndex: 0,
  };

  toggleModal = (i) => {
    this.setState(
      {
        modalIndex: i,
      },
      (_) => {
        this.setState({ modalIsOpen: !this.state.modalIsOpen });
      }
    );
  };

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Events | HES Sound</title>
          <description>
            Underground House Music Event Poland Bialystok Warsaw Europe Hes
            Sound. Not just a Sound system, not just a collective, not just a
            party… 🫀 Music needs to be moving, but leave some breathing space
            for the mind and soul..
          </description>
        </Helmet>

        <StaticQuery
          query={graphql`
            query GalleryPosts {
              allContentfulEventFlyer(
                sort: { order: DESC, fields: eventDate }
              ) {
                edges {
                  node {
                    eventDate
                    flyerImage {
                      file {
                        details {
                          image {
                            height
                            width
                          }
                        }
                      }
                      gatsbyImageData(layout: FULL_WIDTH)
                      description
                    }
                  }
                }
              }
            }
          `}
          render={(data) => {
            const photos = data.allContentfulEventFlyer.edges.reduce(
              (acc, photo) => {
                acc.push({
                  src: photo.node.flyerImage.gatsbyImageData.images.fallback
                    .src,
                  width: photo.node.flyerImage.file.details.image.width,
                  height: photo.node.flyerImage.file.details.image.height,
                });
                // return acc.sort(() => Math.random() - 0.5);
                return acc;
              },
              []
            );

            return (
              <>
                <GalleryElm
                  fotki={data.allContentfulEventFlyer.edges}
                  showModal={this.toggleModal}
                />
                <ModalGateway>
                  {this.state.modalIsOpen ? (
                    <Modal
                      onClose={(_) => {
                        this.toggleModal();
                      }}
                    >
                      <Carousel
                        currentIndex={this.state.modalIndex}
                        views={photos}
                      />
                    </Modal>
                  ) : null}
                </ModalGateway>
              </>
            );
          }}
        />
      </>
    );
  }
}

export default EventsGallery;
